import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 80 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M36.6 52.87a1.37 1.37 0 0 1-1.3-1l-5.76-13.54h-4.53v19.4h3.48V44.16h.24s4.18 10.39 4.57 11.22a1 1 0 0 0 .93.68h11.14c1.14 0 1.2-.65 1.2-.65l4.63-11.25h.27v13.57h3.33v-19.4h-4.66s-4.84 12.59-5.29 13.63a1.39 1.39 0 0 1-1.32.95s-5.88.06-6.93-.04m.7-1.95a.5.5 0 0 1-.18 0l-6.38-14.16H23.1v22.57h7.2V53.1c.63 1.57 1.48 3.67 1.71 4.19a1.41 1.41 0 0 0 1.33.85h13.1a2 2 0 0 0 1.67-1.07l1.61-4v6.25h6.85V36.77h-7.81l-.09.24c-.06.13-5.2 13.21-5.38 13.65a.56.56 0 0 1-.33.3c-1.81.01-5.25.02-5.66 0zm5.79.75a1.31 1.31 0 0 0 .91-.77c.16-.41 4.55-11.57 5.28-13.41h6.5v21h-5.33v-9.32l-3.06 7.59a1.21 1.21 0 0 1-1 .62H33.3a.68.68 0 0 1-.63-.39c-.36-.83-2.41-5.95-2.43-6l-.73-1.83v9.41h-5.66V37.52h6.45l6.12 13.6c0 .24.28.56.89.6s5.49 0 5.78-.01zm27.83-20.68-30-22.62a1.8 1.8 0 0 0-1.1-.37 1.87 1.87 0 0 0-1.1.37l-30 22.62A1.84 1.84 0 0 0 8 32.45v38.09a1.82 1.82 0 0 0 1.82 1.82h60a1.83 1.83 0 0 0 1.82-1.82V32.45a1.86 1.86 0 0 0-.72-1.46m-1.28 39.37H10V32.54l29.82-22.49 29.82 22.49z" }, null, -1)
  ])))
}
export default { render: render }